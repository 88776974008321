import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import baner from '../assets/images/main-slider/slider1/slider-bg1.jpg';
import logowhite from '../assets/images/logo-white-2.png';
import shape4 from '../assets/images/side-images/shape4.png';
import line4 from '../assets/images/side-images/shape-line4.png';
import shape5 from '../assets/images/side-images/shape5.png';
import line5 from '../assets/images/side-images/shape-line5.png';
import location from '../assets/images/footer-location.png';
import logo from './../assets/images/ajudeumacausa/fulllogo_transparent_nobuffer.png';
import ic_logo from './../assets/images/ic_assets/logotipo/logo-white-nobg.png';


import AOS from 'aos';
import 'aos/dist/aos.css';

import $ from 'jquery';

AOS.init();


const Footer2 = () => {

    const [showConsultarNumero, setShowConsultarNumero] = useState(false);

    useEffect(() => {
        setShowConsultarNumero(document.getElementById('consultarNumero') !== null);
    }, []);

    return (
        <>
            <footer className="bg-light py-5 aos-item" data-aos="fade-up">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 col-md-6">
                            <h5 className="subtitle text-primary">APOIE NOSSOS PROJETOS</h5>
                            <h2>AJUDE UMA CAUSA</h2>
                            <p>Ajude uma Causa é uma plataforma dedicada à arrecadação de fundos para apoiar você e seus
                                sonhos.</p>
                        </div>
                        <div className="col-12 col-md-6"></div>
                    </div>

                    <div className="footer-content bg-dark p-5 rounded">
                        <div className="row row-cols-1 row-cols-md-4">
                            <div className="col">
                                <h5 className="subtitle ">ORGANIZAÇÃO</h5>
                                <img src={logo}
                                     className="w-50" alt="Apoie uma causa"/>
                            </div>
                            <div className="col">
                                <h5 className="subtitle ">CAUSA</h5>
                                <a href="https://institutocaramelo.org/"><img src={ic_logo} className="w-100" alt="Instituto Caramelo"/></a>
                            </div>
                            <div className="col">
                                <h5 className="subtitle ">NAVEGUE</h5>
                                <ul>
                                    <li><a href="/ajude-o-rs">AJUDE O RS</a></li>
                                    <li><a href="/sorteio-pelos-animais-instituto-caramelo-fiat-mobi-2025">SORTEIO</a></li>
                                    <li><a href="#sobre">SOBRE NÓS</a></li>
                                    {showConsultarNumero && <li><a href="#participe"
                                           onClick={() => {
                                               $("#consultarNumero").click();
                                           }}
                                           data-bs-toggle="modal" data-bs-target="#consultarModal">CONSULTAR
                                        NÚMERO</a></li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="py-5 disclaimer text-center">
                    {/*    <p>Termos e Política de Privacidade. Lorem ipsum dolor sit amet, consectetur adipisicing elit,*/}
                    {/*        sed do eiusmod*/}
                    {/*        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,*/}
                    {/*        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo*/}
                    {/*        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse*/}
                    {/*        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non*/}
                    {/*        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}
                        <p>Ajude Uma Causa 2024.</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

AOS.init({
    easing: 'ease-in-out-sine'
});

$(function() {
    $(window).scroll(function() {
        var scrollTop = $(window).scrollTop();

        if (scrollTop > 80) {
            $("#header").addClass('header-scroll');
        } else {
            $("#header").removeClass('header-scroll');
        }
    });
});
$(document).ready(function(){
    //
    // var waypoints = $('.aos-item').waypoint(function(direction) {
    //     $('ul.nav a').removeClass('active');
    //     $('ul.nav a[href*="'+this.element.id+'"]').addClass('active');
    // }, {
    //     offset: '160px'
    // })

    $('.scroll').on('click', function(event) {

        var target = $(this.getAttribute('href'));

        if( target.length ) {
            event.preventDefault();
            $('html, body').stop().animate({
                scrollTop: target.offset().top
            }, 800);
        }
    });

    // set up hover panels
    // although this can be done without JavaScript, we've attached these events
    // because it causes the hover to be triggered when the element is tapped on a touch device
    $('.hover').hover(function(){
        $(this).addClass('flip');
    },function(){
        $(this).removeClass('flip');
    });
});



export default Footer2;