import {Link} from 'react-router-dom';

import bg from '../assets/images/background/bg2.jpg';
import {useCookies} from "react-cookie";
const RedirectWithCookies = () => {
    const [cookies, setCookie] = useCookies(['promoter']);

    // if has more than 1 path
    if (window.location.pathname.split('/').length > 2) {
        // the first path is the promoter id
        const promoterId = window.location.pathname.split('/')[1];

        // the second path is the promoter name
        const promoterName = window.location.pathname.split('/')[2];

        // Set the cookie with the promoter name and expiration date indeterminate
        setCookie('promoterName', promoterName, {path: '/', maxAge: 60 * 60 * 24 * 365 * 10});

        // set the cookie with the promoter id and expiration date indeterminate
        setCookie('promoterId', promoterId, {path: '/', maxAge: 60 * 60 * 24 * 365 * 10});

        window.location.href = `https://ajudeumacausa.com.br/`;

    } else {
        // the first path is the promoter name
        const promoterName = window.location.pathname.split('/')[1];

        // Set the cookie with the promoter name and expiration date indeterminate
        setCookie('promoterName', promoterName, {path: '/', maxAge: 60 * 60 * 24 * 365 * 10});

        window.location.href = `https://ajudeumacausa.com.br/`;
    }
};

export default RedirectWithCookies;