import React, {useState, useEffect, useReducer} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {MenuListArray2} from './Menu';
import '../assets/scss/custom/_instituto-caramelo.scss'

import $ from 'jquery';

import logo from './../assets/images/ic_assets/logotipo/logo-black-nobg.png';


const Header = (modalRef) => {

    //form submit
    const nav = useNavigate();
    const formSubmit = (e) => {
        e.prevantDefault();
        nav("/");
    }

    /* for sticky header */
    const [headerFix, setheaderFix] = React.useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setheaderFix(window.scrollY > 50);
        });
    }, []);

    //Modal
    const [loginModal, setloginModal] = useState(false);
    const [resetModal, setResetModal] = useState(false);
    const [signupModal, setSignupModal] = useState(false);
    //Modals end

    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        var mainMenu = document.getElementById('OpenMenu');
        if (mainMenu) {
            if (sidebarOpen) {
                mainMenu.classList.add('show');
            } else {
                mainMenu.classList.remove('show');
            }
        }
    });

    // Menu dropdown list
    const reducer = (previousState, updatedState) => ({
        ...previousState,
        ...updatedState,
    });
    const initialState = {
        active: "",
        activeSubmenu: "",
    }
    const [state, setState] = useReducer(reducer, initialState);
    const handleMenuActive = status => {
        setState({active: status});
        if (state.active === status) {
            setState({active: ""});
        }
    }

    const handleSubmenuActive = (status) => {
        setState({activeSubmenu: status})
        if (state.activeSubmenu === status) {
            setState({activeSubmenu: ""})
        }
    }

    //let path = window.location.pathname;
    // Menu dropdown list End
    return (
        <>
            <header className="site-header mo-left header style-1 mt-0">
                <nav className="top-nav bg-primary">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-md-2">
                            <div className="text-center text-md-start">
                                <a href="mailto:contato@ajudeumacausa.com.br"><i className="fa-solid fa-envelope"></i> contato@ajudeumacausa.com.br</a>
                            </div>
                            <div className="text-center text-md-end social-media">
                                <a href="https://www.facebook.com/InstitutoCaramelo/"><i className="lab la-facebook-f"></i></a>
                                <a href="https://www.instagram.com/instituto.caramelo/"><i className="lab la-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="container">
                    <header id="header"
                            className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 px-5 mb-4 fixed-top">

                        <div className="col-12 col-md-3 mb-2 mb-md-0 text-center text-md-start">
                            <a href="/" className="d-md-inline-flex link-body-emphasis text-decoration-none">
                                <img src={logo} className="brand w-100" alt="Instituto Caramelo"/>
                            </a>
                        </div>

                        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0 d-none d-md-inline-flex">
                            <li><a href="/ajude-o-rs" className="nav-link px-2">AJUDE O RS</a></li>
                            <li><a href="/sorteio-pelos-animais-instituto-caramelo-fiat-mobi-2025" className="nav-link px-2">SORTEIO</a></li>
                            <li><a href="#sobre" className="nav-link px-2">SOBRE NÓS</a></li>
                        </ul>

                        <div className="col-md-4 text-end participe-wrapper">
                            <button type="button" className="btn btn-outline-primary me-2" data-bs-toggle="modal"
                                    onClick={() => {
                                        $("#doar").click();
                                    }}
                                    data-bs-target="#participeModal"><i className="lab la-gratipay"></i> {window.location.pathname.split('/')[1].includes("ajude-o-rs") ? "FAÇA UMA DOAÇÃO" : "ADQUIRA SEU NÚMERO"}
                            </button>
                        </div>
                    </header>
                </div>
            </header>
        </>
    );
};

export default Header;