import React from 'react';
import {Link} from 'react-router-dom';

import bg from '../assets/images/background/bg2.jpg';

const Error = () => {
    return (
        <>
            <div className="error-page">
                <div className="error-inner text-center">
                    <div className="dz-error">404</div>
                    <h2 className="error-head">Lamentamos, mas a página que você procura não pode ser encontrada.</h2>
                    <Link to={"/"} className="btn btn-secondary">VOLTAR PARA A PÁGINA INICIAL</Link>
                </div>
            </div>
        </>
    );
};


export default Error;