import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import img1 from '../../assets/images/blog/large/pic1.jpg';
import img2 from '../../assets/images/blog/large/pic2.jpg';
import img3 from '../../assets/images/blog/large/pic3.jpg';
import img4 from '../../assets/images/blog/large/pic4.jpg';
import img5 from '../../assets/images/blog/large/pic5.jpg';
import img6 from '../../assets/images/blog/large/pic6.jpg';

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

let swiperFirst = [
    {image: img1},{image: img2},
    {image: img3},{image: img4},
    {image: img5},{image: img6},
];

export default function GallerySlider(imgs) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

    swiperFirst = imgs.imgs;

  return (
    <>
      <Swiper 
        spaceBetween={10}
        navigation={true}
        autoplay={true}
        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
        modules={[FreeMode, Navigation, Thumbs]}
        className="fundraiser-gallery-swiper text-center"
      >
        {swiperFirst.map((item, index)=>(
            <SwiperSlide key={index}>
                <SwiperSlide className="dz-media"><img src={item.image} alt="" /></SwiperSlide>
            </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
