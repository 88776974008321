import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';

import GallerySlider from '../components/Fundraiser/GallerySlider';
import DoacaoSorteioModal from "../components/Modal/DoacaoSorteioModal";
import DoacaoConsultaModal from "../components/Modal/DoacaoConsultaModal";

import CountUp from "react-countup";
import {IMAGES} from "../constant/theme";

import FoundCounter from "../components/Widget/FoundCounter";

import cat from "../assets/images/ic_assets/animals/gato.png";
import dog from "../assets/images/ic_assets/animals/cachorro.png";

import iPhoneAppleWatch from './../assets/images/prizes/iPhoneAppleWatch.png';

import axios from "axios";

const FundraiserDetail = () => {

    const formater = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    const [list, setList] = React.useState(
        {id: 1, raised: 0, goal: 0, supporters: 0, daysLeft: 0},
    );

    React.useEffect(() => {
        getDetails();
    }, []);

    function getDetails() {

    }

    const percentGoal = (raised, goal) => {
        return (raised / goal) * 100;
    };

    let {raised, goal, supporters, daysLeft} = list;
    const percentage = percentGoal(raised, goal);

    // raised = formater.format(raised);
    goal = formater.format(goal);

    const modalRef = useRef(null);
    const modalConsultRef = useRef(null);

    return (
        <>
            <div className="page-content bg-white">
                <div className="banner-one">
                    <section className="content-inner-1 p-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 col-lg-8 m-b30 pt-1">
                                    <h3> 
                                        Sorteio pelos Animais
                                    </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8 col-lg-8 m-b30">
                                    <div className="fundraiser-single">
                                        <div className="title">
                                            <div className="premio">
                                                <p><img src={iPhoneAppleWatch} alt="" width={200} /></p>
                                                <h5>Concorra a 1 iPhone 15 Pro Max + 1 Apple Watch SE</h5>
                                                <p>A cada R$ 20,00 doados, uma chance de ganhar!</p>
                                            </div>
                                            <div className="descricao">
                                                <ul className="fundraiser-bottom">
                                                    <li>
                                                        <Link to={"#"} className="btn btn-primary btn-sm"
                                                              onClick={() => {
                                                                  modalRef.current.handleModalOpen();
                                                              }}
                                                              data-bs-target="#modalDonate">
                                                            <i className="flaticon-like me-2"></i> Adquira seu número
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="detalhes-sorteio">
                                                <p>
                                                    Junte-se a nós nesta campanha incrível em prol dos animais! Ao
                                                    adquirir um número do Sorteio você não apenas concorre a um prêmio
                                                    espetacular,
                                                    mas
                                                    também se junta a uma causa nobre e essencial para a proteção e
                                                    cuidado de animais resgatados.
                                                </p>
                                                <h4>Detalhes do Sorteio:</h4>
                                                <p>Valor por número: R$ 20,00</p>
                                                <p>Data do sorteio: 31/01/2024</p>
                                                <p>Meio: Loteria Federal</p>
                                                <p>
                                                    <a href="https://cdn.ajudeumacausa.com.br/termos/Certificado_autorizacao_010314152023-1.pdf"
                                                       target="_blank">Certificado</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <a href="https://cdn.ajudeumacausa.com.br/termos/Regulamento_SORTEIO_PELOS_ANIMAIS_-_0202307634-4.pdf"
                                                       target="_blank">Regulamento</a>
                                                </p>
                                            </div>
                                            {/*
                                            <FoundCounter raised={raised} goal={goal} percentage={percentage}
                                                        supporters={supporters} daysLeft={daysLeft} mobile={true}/>


                                                <div className="como-participar">
                                                    <h4>Como adquirir seu número e ajudar:</h4>
                                                    <p>[BOTAO DA CAMPANHA]</p>
                                                </div>
                                            */}
                                        </div>
                                        <ul className="fundraiser-bottom">
                                            <li>
                                                <Link to={"#"} className="btn btn-primary btn-sm"
                                                      onClick={() => {
                                                          modalRef.current.handleModalOpen();
                                                      }}
                                                      data-bs-target="#modalDonate">
                                                    <i className="flaticon-like me-2"></i> Adquira seu número
                                                </Link>
                                            </li>
                                            <li><a href="https://wa.me/?text=https://www.sorteiopelosanimais.org/sorteio-pelos-animais-instituto-caramelo" target="_blank" rel="noreferrer"
                                                   className="btn whatsapp btn-primary btn-sm"><i
                                                className="fa-brands fa-whatsapp me-2"></i>Compartilhe</a></li>
                                            <li>
                                                <Link className="btn btn-donate btn-secondary btn-sm"
                                                    // Open modal
                                                      onClick={() => {
                                                          modalConsultRef.current.handleModalOpen();
                                                      }}
                                                      data-bs-toggle="modal" data-bs-target="#modalConsultDonate"><i
                                                    className="flaticon-check me-3"></i> Consultar meus números </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="widget style-1 widget_refer">
                                        <div className="widget-title">
                                            <h4 className="title">O Instituto Caramelo </h4>
                                        </div>
                                        <GallerySlider imgs={
                                            [
                                                {image: IMAGES.ICImageSlide1},
                                                {image: IMAGES.ICImageSlide2},
                                                {image: IMAGES.ICImageSlide3},
                                                {image: IMAGES.ICImageSlide4},
                                                {image: IMAGES.ICImageSlide5},
                                                {image: IMAGES.ICImageSlide6},
                                                {image: IMAGES.ICImageSlide7},
                                                {image: IMAGES.ICImageSlide8},
                                            ]
                                        }></GallerySlider>
                                        <br/>
                                        <p>Fundado em fevereiro de 2015, a partir da união de um grupo de pessoas em
                                            prol do
                                            propósito de cuidar bem e adotar bem cada animal, o Instituto Caramelo atua
                                            principalmente no resgate de animais feridos ou em situação de risco,
                                            recuperação e adoção. Mantemos um abrigo com cerca de 300 animais, entre
                                            cães e
                                            gatos, todos resgatados das ruas, onde eles são protegidos, tratados,
                                            alimentados e aguardam pela chance de serem adotados.
                                        </p>
                                        <p>O Instituto Caramelo fica na cidade de Ribeirão Pires/SP, em um
                                            terreno
                                            de 27.000 metros quadrados. Há centro cirúrgico com atendimento veterinário
                                            24
                                            horas, núcleo de fisioterapia, adestramento para tratamento psicológico, 3
                                            áreas de
                                            soltura, 30 gatis e canis e uma equipe de mais de 40 profissionais que se
                                            doam por
                                            completo para que o abrigo seja apenas uma fase de passagem na vida dos
                                            bichos. São
                                            necessários cerca de 300 mil reais por mês para sustentá-lo e são as
                                            doações,
                                            parcerias e vendas na loja que tornam esse sonho possível.
                                        </p>
                                        <Link to={"https://institutocaramelo.org/"} className="btn btn-outline-primary"
                                              target="_blank">Saiba
                                            mais</Link>
                                    </div>
                                </div>


                            </div>
                            <div className="row banner-inner d-none"></div>

                        </div>
                    </section>
                </div>
                <DoacaoSorteioModal
                    ref={modalRef}
                ></DoacaoSorteioModal>
                <DoacaoConsultaModal ref={modalConsultRef}>
                </DoacaoConsultaModal>
            </div>
        </>
    )
}

export default FundraiserDetail;