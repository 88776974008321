import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import GallerySlider from '../components/Fundraiser/GallerySlider';
import DoacaoSorteioModal from "../components/Modal/DoacaoSorteioModal";
import DoacaoConsultaModal from "../components/Modal/DoacaoConsultaModal";

import CountUp from "react-countup";
import { IMAGES } from "../constant/theme";

import FoundCounter from "../components/Widget/FoundCounter";

import cat from "../assets/images/ic_assets/animals/gato.png";
import dog from "../assets/images/ic_assets/animals/cachorro.png";

import HondaCity from '../assets/images/prizes/Novo-Honda-City-2018-6-150px.jpeg';

import DogBanner from './../assets/images/prizes/dog-banner.png';

import axios from "axios";
import DoacaoRSModal from '../components/Modal/DoacaoRSModal';

import {Helmet} from "react-helmet";

const FundraiserDetail = () => {

    const formater = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    const [list, setList] = React.useState(
        { id: 1, raised: 0, goal: 0, supporters: 0, daysLeft: 0 },
    );

    React.useEffect(() => {
        getDetails();
    }, []);

    function getDetails() {

    }

    const percentGoal = (raised, goal) => {
        return (raised / goal) * 100;
    };

    let { raised, goal, supporters, daysLeft } = list;
    const percentage = percentGoal(raised, goal);

    // raised = formater.format(raised);
    goal = formater.format(goal);

    const modalRef = useRef(null);

    const modalConsultRef = useRef(null);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        document.title = 'Ajude o RS - Instituto Caramelo';

        // Set description meta tag
        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.setAttribute("content", "Ajude o RS - Instituto Caramelo");
            metaDescription.setAttribute("og:description", "Ajude o RS - Instituto Caramelo");
        metaDescription.setAttribute("og:title", "Ajude o RS - Instituto Caramelo");

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const isMobile = width <= 768;

    const carousel = [
        "https://cdn.ajudeumacausa.com.br/causes/ajude-o-rs/ajude-o-rs-2.jpeg",
        "https://cdn.ajudeumacausa.com.br/causes/ajude-o-rs/ajude-o-rs-7.jpeg",
        "https://cdn.ajudeumacausa.com.br/causes/ajude-o-rs/ajude-o-rs-4.jpeg",
        "https://cdn.ajudeumacausa.com.br/causes/ajude-o-rs/ajude-o-rs-9.jpeg",
        "https://cdn.ajudeumacausa.com.br/causes/ajude-o-rs/ajude-o-rs-10.jpeg",
        "https://cdn.ajudeumacausa.com.br/causes/ajude-o-rs/ajude-o-rs-11.jpeg",
    ]

    const [carouselIndex, setCarouselIndex] = useState(0);

    return (
        <>
            <main>
                <Helmet>
                    <title>Ajude o RS - Instituto Caramelo</title>
                    <meta name="description" content="Ajude o RS - Instituto Caramelo" />
                    <meta property="og:title" content="Ajude o RS - Instituto Caramelo" />
                    <meta property="og:description" content="Ajude o RS - Instituto Caramelo" />
                </Helmet>
                <section id="sorteio" className="featured bg-secondary">
                    <div className="overlay"></div>
                    <div className="container d-flex align-items-center">
                        <div className="row row-cols-md-2 ">
                            <div className="px-5">
                                <h5 className="subtitle text-primary">DOE E AJUDE</h5>
                                <h1>Ajude o RS</h1>
                                <p className={"pb-4 text-left"}>
                                    O Instituto Caramelo tem como compromisso apoiar o resgate de animais em desastres,
                                    como fizemos em Mariana, Brumadinho, enchentes da Bahia e Pantanal. <br />
                                    Ao lado de quem atua na ponta, enviamos recursos e ficamos à frente de logísticas
                                    complexas para que as operações possam acontecer! <br />
                                    Faça a sua doação e nos ajude a salvar vidas! <br />
                                    Somente juntos somos capazes de mudar essa triste realidade. <br />
                                    <br />
                                    <b>Como estamos agindo no Rio Grande do Sul?</b>
                                    <ul>
                                        <li>Envio de 3 socorristas</li>
                                        <li>Compra de 3 barcos</li>
                                        <li>Logística do envio dos barcos</li>
                                        <li>Caminhão com 1,4 T de ração + caixas de transporte</li>
                                        <li>Carro 4x4</li>
                                    </ul>

                                    <div className={"text-center"}>
                                        Estamos firmes nas operações e agindo conforme demanda uma vez que o cenário muda a cada dia!
                                        <br />
                                        <h5><b>Sua doação é o que nos possibilita ir a diante!</b>
                                        </h5>
                                    </div>

                                </p>
                            </div>
                            <div>
                                <div className="featured-cta mx-md-5">
                                    <img src={DogBanner} />
                                    <h2><b className="text-primary">Ajude o RS</b></h2>
                                    <p><b>Faça a diferença hoje: ajude-nos a trazer esperança e assistência às vítimas das enchentes no Rio Grande do Sul!</b></p>
                                    <button className="btn btn-md btn-primary my-3 p-3"
                                        id="doar"
                                        onClick={() => {
                                            modalRef.current.handleModalOpen();
                                        }}>
                                        <i className="lab la-gratipay"></i>FAÇA UMA DOAÇÃO
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*
                Carrousel

                */}
                <section id="">

                </section>
                <section id="galeria" className="py-5 aos-item" data-aos="fade-up">
                    <GallerySlider imgs={
                        [
                            {image: carousel[0]},
                            {image: carousel[1]},
                            {image: carousel[2]},
                            {image: carousel[3]},
                            {image: carousel[4]},
                            {image: carousel[5]}
                        ]
                    }></GallerySlider>
                </section>
                <section id="sobre" className="py-5 aos-item" data-aos="fade-up">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <h5 className="subtitle text-primary">SOBRE</h5>
                                <h2>Instituto Caramelo</h2>
                                <hr className="divider" />
                                <p>Fundado em fevereiro de 2015, a partir da união de um grupo de pessoas em prol do
                                    propósito de cuidar bem e adotar bem cada animal, o Instituto Caramelo atua
                                    principalmente no resgate de animais feridos ou em situação de risco, recuperação e
                                    adoção. Mantemos um abrigo com cerca de 300 animais, entre cães e gatos, todos
                                    resgatados das ruas, onde eles são protegidos, tratados, alimentados e aguardam pela
                                    chance de serem adotados.</p>

                                <p>O Instituto Caramelo fica em Ribeirão Pires, na região metropolitana, em um terreno
                                    de 27.000 metros quadrados. Há centro cirúrgico com atendimento veterinário 24
                                    horas, núcleo de fisioterapia, adestramento para tratamento psicológico, 5 áreas de
                                    soltura, 35 canis, 4 gatis e uma equipe de mais de 40 profissionais que se doam por
                                    completo para que o abrigo seja apenas uma fase de passagem na vida dos bichos. São
                                    necessários cerca de 300 mil reais por mês para sustentá-lo e são as doações,
                                    parcerias e vendas na loja que tornam esse sonho possível.</p>

                                <a href="https://institutocaramelo.org/" className="btn btn-md btn-dark"
                                    target="_blank">Saiba mais</a>

                            </div>
                            <div className="col-12 col-md-6 px-md-5">
                                <img src={IMAGES.About00} className="rounded w-100 m-md-3" />
                                <div className="row">
                                    <div className="col">
                                        <img src={IMAGES.About03} className="rounded w-100 m-md-3" />

                                    </div>
                                    <div className="col">
                                        <img src={IMAGES.About04} className="rounded w-100 m-md-3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <DoacaoRSModal
                ref={modalRef}
            ></DoacaoRSModal>
            <DoacaoConsultaModal ref={modalConsultRef}>
            </DoacaoConsultaModal>
        </>
    )
}

export default FundraiserDetail;