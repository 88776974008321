import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import $ from 'jquery';

import * as Sentry from "@sentry/react";

import App from './App';
import reportWebVitals from './reportWebVitals';

import  ThemeContext  from "./context/ThemeContext";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-8RYDVS9LJ4');

const root = ReactDOM.createRoot(document.getElementById('root'));

const {REACT_APP_STAGE} = process.env;

const domain = window.location.hostname;

if (!domain.endsWith('ajudeumacausa.com.br') && !domain.endsWith('localhost') && REACT_APP_STAGE === 'api') {
    // replace the domain to ajudeumacausa.com.br, and keep paths
    const path = window.location.pathname;
    const newUrl = `https://ajudeumacausa.com.br${path}`;

    window.location.href = newUrl;
}

Sentry.init({
    dsn: "https://0d00078cb9a826bc667491837116ba17@o4506384935288832.ingest.sentry.io/4506399648448512",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/apidev\.ajudeumacausa\.com\.br/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeContext>
        <App />
      </ThemeContext>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
